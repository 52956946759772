import * as SelectPrimitives from "@radix-ui/react-select"
import { ColorType, styled, theme } from "../../styles/stitches.config"
import { ComponentProps, PropsWithChildren, ReactNode, useId } from "react"
import {
  SArrowDown,
  SArrowUp,
  SContainer,
  SContent,
  SGroupLabel,
  SIcon,
  SItem,
  SLabel,
  SScrollDownButton,
  SScrollUpButton,
  STrigger,
  SViewport,
} from "./Select.styled"
import { Spacer } from "../spacer/Spacer"
import { ObjectValues } from "../../utils/types"

interface SelectProps {
  value?: string
  onValueChange?: (value: string) => void
  defaultValue?: string
  placeholder?: string
  label?: ReactNode
  isFullWidth?: boolean
  size?: ComponentProps<typeof STrigger>["size"]
  textColor?: ColorType
  position?: ComponentProps<typeof SContent>["position"]
  width?: ObjectValues<typeof theme.sizes> | "max"
}

const RawSelect = ({
  children,
  value,
  defaultValue,
  onValueChange,
  placeholder,
  size,
  textColor,
  id,
  position,
  width,
}: Omit<PropsWithChildren<SelectProps> & { id: string }, "label">) => {
  return (
    <SelectPrimitives.Root defaultValue={defaultValue} value={value} onValueChange={onValueChange}>
      <STrigger
        id={id}
        isFullWidth={width === "max"}
        size={size}
        css={{ width, color: textColor ? theme.colors[textColor].value : undefined }}
      >
        <SelectPrimitives.Value placeholder={placeholder} />

        <SIcon size={size}>
          <SArrowDown />
        </SIcon>
      </STrigger>
      <SelectPrimitives.Portal>
        <SContent
          position={position}
          css={position === "popper" ? { width: "var(--radix-select-trigger-width)" } : undefined}
          sideOffset={8}
        >
          <SScrollUpButton>
            <SArrowUp />
          </SScrollUpButton>
          <SViewport>{children}</SViewport>
          <SScrollDownButton>
            <SArrowDown />
          </SScrollDownButton>
        </SContent>
      </SelectPrimitives.Portal>
    </SelectPrimitives.Root>
  )
}

export function Select({ label, ...props }: PropsWithChildren<SelectProps>) {
  const id = useId()
  if (label) {
    return (
      <SContainer>
        <SLabel htmlFor={id}>{label}</SLabel>
        <Spacer size={theme.space.s4} />

        <RawSelect {...props} id={id} />
      </SContainer>
    )
  }

  return <RawSelect {...props} id={id} />
}

interface SelectItemProps {
  value: string
  text: string
  disabled?: boolean
  size?: ComponentProps<typeof SItem>["size"]
  color?: ColorType
}

export function SelectItem({ value, text, disabled, size, color }: SelectItemProps) {
  return (
    <SItem value={value} disabled={disabled} size={size} css={color && { color: theme.colors[color].value }}>
      <SelectPrimitives.SelectItemText>{text}</SelectPrimitives.SelectItemText>
    </SItem>
  )
}

interface SelectGroupProps {
  label: string
}

export function SelectGroup({ label, children }: PropsWithChildren<SelectGroupProps>) {
  return (
    <SelectPrimitives.Group>
      <SGroupLabel>{label}</SGroupLabel>
      {children}
    </SelectPrimitives.Group>
  )
}

export const GroupSeparator = styled(SelectPrimitives.Separator, {
  height: 1,
  backgroundColor: theme.colors.lines,
  margin: theme.space.s2,
})
