/**
 * This module provides utility functions for working with Excel files in the frontend of the Nano Portal app.
 * It exports functions for transforming spot data into Excel sheets, downloading those sheets, and cloning worksheets
 * into workbooks with new names. It also exports a custom React hook for downloading spot data sheets.
 * @packageDocumentation
 */
import { formatDate } from "./date"
import { InvoiceSpotData, SpotInvoiceDetail, dropDuplicates } from "@nano-portal/shared"
import { sortBy } from "lodash"

import * as Excel from "exceljs"
import axios from "axios"
import { useMutation } from "react-query"

type XlsxSheetConfig = Excel.Workbook
type XlsxDownloadSettings = { fileName: string }
type BillingSheetType = "consumption" | "production"

const MAX_SHEET_NAME_LENGTH = 31
const FIRST_SPOT_TABLE_ROW_NUMBER = 7

export async function transformSpotDataToSheets(data: SpotInvoiceDetail[]) {
  // TODO nacitat sablony on-demand?
  const [consumptionTemplate, productionTemplate] = await loadBillingWorkbookTemplates()
  if (!consumptionTemplate || !productionTemplate) {
    return
  }

  const workbook = new Excel.Workbook()
  for (const spotData of data.sort((a, b) => a.billedSince.getTime() - b.billedSince.getTime())) {
    const workbookTemplate = spotData.deliveryPointType === "consumption" ? consumptionTemplate : productionTemplate
    const sheetTemplate = workbookTemplate.getWorksheet()
    if (!sheetTemplate) {
      return
    }

    const sheet = cloneSheetIntoWorkbook(sheetTemplate, workbook, getSheetName(spotData))
    fillBillingPeriodCell(sheet, spotData.billedSince, spotData.billedUntil)
    fillEanCell(sheet, spotData.ean)

    let lastRow: Excel.Row | undefined
    const sortedSpotData = sortBy(spotData.spotData, ["dateTime"])
    const fillRowWithSpotData = getRowSpotDataFiller(spotData.deliveryPointType)
    for (const [index, item] of sortedSpotData.entries()) {
      const row = sheet.getRow(FIRST_SPOT_TABLE_ROW_NUMBER + index)
      fillRowWithSpotData(row, item)

      lastRow = row
    }

    if (lastRow) {
      const firstRow = sheet.getRow(FIRST_SPOT_TABLE_ROW_NUMBER)
      fillTotalPeriodQuantityCell(sheet, firstRow, lastRow)
      fillTotalPeriodPriceCell(sheet, firstRow, lastRow)
    }
  }

  return workbook
}

export function getSpotDataXlsxSettings(data: SpotInvoiceDetail[]): XlsxDownloadSettings {
  return {
    fileName: `podklad-pro-zuctovani-${dropDuplicates(data.map((item) => item.ean)).join("-")}`,
  }
}

export async function downloadXlsxSheets<
  M extends "file" | "buffer" = "file",
  R = M extends "file" ? void : Excel.Buffer
>(workbook: XlsxSheetConfig, mode: M, settings?: XlsxDownloadSettings): Promise<R> {
  if (mode === "buffer") {
    return workbook.xlsx.writeBuffer() as R
  }

  const buffer = await workbook.xlsx.writeBuffer({ filename: settings?.fileName })
  downloadExcelBuffer(buffer, settings?.fileName ?? "export.xlsx")
  return undefined as R
}

export function useDownloadSpotDataSheetMutation() {
  const downloadXlsxSheetsMutation = useMutation({
    mutationFn: async (spotData: SpotInvoiceDetail[]) => {
      const sheets = await transformSpotDataToSheets(spotData)
      if (!sheets) {
        return
      }

      const settings = getSpotDataXlsxSettings(spotData)
      await downloadXlsxSheets(sheets, "file", settings)
    },
  })

  return downloadXlsxSheetsMutation
}

function loadBillingWorkbookTemplates() {
  return Promise.all([loadBillingWorkbookTemplate("consumption"), loadBillingWorkbookTemplate("production")])
}

function downloadExcelBuffer(buffer: Excel.Buffer, fileName: string) {
  const a = document.createElement("a")
  a.href = URL.createObjectURL(new Blob([buffer]))
  a.download = fileName.endsWith(".xlsx") ? fileName : `${fileName}.xlsx`
  a.click()
  URL.revokeObjectURL(a.href)
}

async function loadBillingWorkbookTemplate(sheetType: BillingSheetType): Promise<Excel.Workbook | undefined> {
  try {
    const data = await axios.get<ArrayBuffer>(`/sheets/billing-attachment-${sheetType}.xlsx`, {
      responseType: "arraybuffer",
    })
    const workbook = new Excel.Workbook()
    await workbook.xlsx.load(data.data)
    return workbook
  } catch {
    return
  }
}

function getRowSpotDataFiller(sheetType: BillingSheetType) {
  return sheetType === "consumption" ? fillRowWithConsumptionSpotDataValues : fillRowWithProductionSpotDataValues
}

function getSheetName(spotData: SpotInvoiceDetail) {
  return `${spotData.ean}-${formatDate(spotData.billedSince)}`.slice(0, MAX_SHEET_NAME_LENGTH)
}

/**
 * Clones a given worksheet into a workbook with a new name.
 * @param sheet The worksheet to clone.
 * @param workbook The workbook to clone the worksheet into.
 * @param newSheetName The name of the new worksheet.
 * @returns The new worksheet.
 */
function cloneSheetIntoWorkbook(sheet: Excel.Worksheet, workbook: Excel.Workbook, newSheetName: string) {
  const newSheet = workbook.addWorksheet(sheet.name)
  newSheet.model = {
    ...sheet.model,
    id: newSheet.id,
    name: newSheetName,
  }

  // musi se mergenout takhle, jinak se to vynuluje
  for (const merge of sheet.model.merges) {
    newSheet.mergeCells(merge)
  }

  return newSheet
}

function fillRowWithConsumptionSpotDataValues(row: Excel.Row, item: InvoiceSpotData) {
  getDateCell(row).value = formatDate(item.dateTime)
  getHourCell(row).value = item.dateTime.getHours()

  const quantityCell = getQuantityCell(row)
  quantityCell.value = item.quantityKWh
  const unitPriceCell = getUnitPriceCell(row)
  unitPriceCell.value = {
    formula: `(${item.unitPriceKWh}${item.ratio ? `* ${item.ratio}` : ""}${
      item.constant ? `+ ${item.constant}` : ""
    }) * ${item.vatMultiplier}`,
  }

  const spotPriceTotalCell = getTotalSpotPriceCell(row)
  spotPriceTotalCell.value = { formula: `${quantityCell.address} * ${unitPriceCell.address}` }
}

function fillRowWithProductionSpotDataValues(row: Excel.Row, item: InvoiceSpotData) {
  getDateCell(row).value = formatDate(item.dateTime)
  getHourCell(row).value = item.dateTime.getHours()

  const quantityCell = getQuantityCell(row)
  quantityCell.value = item.quantityKWh
  const unitPriceCell = getUnitPriceCell(row)
  unitPriceCell.value = {
    formula: `${item.unitPriceKWh}${item.ratio ? `* ${item.ratio}` : ""} ${item.constant ?? ""}`,
  }

  const spotPriceTotalCell = getTotalSpotPriceCell(row)
  spotPriceTotalCell.value = { formula: `${quantityCell.address} * ${unitPriceCell.address}` }
}

function fillEanCell(sheet: Excel.Worksheet, ean: string) {
  getEanCell(sheet).value = ean
}

function fillBillingPeriodCell(sheet: Excel.Worksheet, billedSince: Date, billedUntil: Date) {
  getBillingPeriodCell(sheet).value = `${formatDate(billedSince)} - ${formatDate(billedUntil)}`
}

function fillTotalPeriodPriceCell(sheet: Excel.Worksheet, firstRow: Excel.Row, lastRow: Excel.Row) {
  const firstTotalPriceCell = getTotalSpotPriceCell(firstRow)
  const lastTotalPriceCell = getTotalSpotPriceCell(lastRow)
  getTotalPeriodPriceCell(sheet).value = {
    formula: `SUM(${firstTotalPriceCell.address}:${lastTotalPriceCell.address})`,
  }
}

function fillTotalPeriodQuantityCell(sheet: Excel.Worksheet, firstRow: Excel.Row, lastRow: Excel.Row) {
  const firstQuantityCell = getQuantityCell(firstRow)
  const lastQuantityCell = getQuantityCell(lastRow)
  getTotalPeriodQuantityCell(sheet).value = {
    formula: `SUM(${firstQuantityCell.address}:${lastQuantityCell.address})`,
  }
}

function getBillingPeriodCell(sheet: Excel.Worksheet) {
  return sheet.getCell("B1")
}

function getEanCell(sheet: Excel.Worksheet) {
  return sheet.getCell("B2")
}

function getTotalPeriodPriceCell(sheet: Excel.Worksheet) {
  return sheet.getCell("B4")
}

function getTotalPeriodQuantityCell(sheet: Excel.Worksheet) {
  return sheet.getCell("B3")
}

function getDateCell(row: Excel.Row) {
  return row.getCell("A")
}

function getHourCell(row: Excel.Row) {
  return row.getCell("B")
}

function getQuantityCell(row: Excel.Row) {
  return row.getCell("C")
}

function getUnitPriceCell(row: Excel.Row) {
  return row.getCell("D")
}

function getTotalSpotPriceCell(row: Excel.Row) {
  return row.getCell("E")
}
