import { PaymentMethod } from "@nano-portal/shared"
import { CopyButton } from "../../components/button/CopyButton"
import Modal from "../../components/modal/Modal"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import {
  ModalTitle,
  ModalDescription,
  DescriptionParagraph,
  BankAccountLabel,
  BankAccountField,
  BankAccount,
  Tooltip,
  ButtonGroup,
  RemindMeLaterButton,
  ConfirmButton,
  ModalContent,
} from "./BankAccountChangeModal.styled"
import { assertUnreachable } from "../../utils/types"
import { Trans, useTranslation } from "react-i18next"

type PaymentMethodVariants = PaymentMethod.BankEncashment | PaymentMethod.BankOrder

type PaymentMethodDescriptionProps = {
  paymentMethod: PaymentMethodVariants
  variableCode: string
}

function PaymentMethodDescription(props: PaymentMethodDescriptionProps) {
  const { t } = useTranslation(["bank_account_change"])
  if (props.paymentMethod === PaymentMethod.BankOrder) {
    return (
      <>
        <DescriptionParagraph>
          <Trans
            t={t}
            i18nKey="bank_account_change:description_bank_order"
            values={{ variableCode: props.variableCode }}
          >
            <strong />
          </Trans>
        </DescriptionParagraph>

        <Spacer size={theme.space.s6} />
        <DescriptionParagraph>{t("bank_account_change:thank_you")}</DescriptionParagraph>
      </>
    )
  }

  return (
    <>
      <DescriptionParagraph>
        <Trans t={t} i18nKey="bank_account_change:description_bank_encashment_1">
          <strong />
        </Trans>
      </DescriptionParagraph>

      <Spacer size={theme.space.s6} />

      <DescriptionParagraph>
        <Trans t={t} i18nKey="bank_account_change:description_bank_encashment_2">
          <strong />
        </Trans>
      </DescriptionParagraph>

      <Spacer size={theme.space.s6} />
      <DescriptionParagraph>{t("bank_account_change:thank_you")}</DescriptionParagraph>
    </>
  )
}

type PaymentMethodHeaderProps = {
  paymentMethod: PaymentMethodVariants
}

function PaymentMethodHeader(props: PaymentMethodHeaderProps) {
  const { t } = useTranslation(["bank_account_change"])
  if (props.paymentMethod === PaymentMethod.BankOrder) {
    return <ModalTitle>{t("bank_account_change:header_bank_order")}</ModalTitle>
  }

  if (props.paymentMethod === PaymentMethod.BankEncashment) {
    return <ModalTitle>{t("bank_account_change:header_bank_encashment")}</ModalTitle>
  }

  return assertUnreachable(props.paymentMethod)
}

type Props = {
  onDismiss?: () => void
  onConfirm?: () => void
  isConfirming?: boolean
  bankAccountNumber: string
} & PaymentMethodDescriptionProps

export function BankAccountChangeModal(props: Props) {
  const { onDismiss, onConfirm, isConfirming, paymentMethod, variableCode, bankAccountNumber } = props
  const { t } = useTranslation(["bank_account_change"])

  return (
    <Modal
      openOnMount
      hideCloseButton
      isFullscreenOnMobile
      disableEscapeKeyToClose
      disablePointerOutsideToClose
      padding={theme.space.s12}
    >
      <ModalContent>
        <PaymentMethodHeader paymentMethod={paymentMethod} />
        <ModalDescription>{t("bank_account_change:dialog_description")}</ModalDescription>

        <Spacer size={theme.space.s6} />

        <PaymentMethodDescription paymentMethod={paymentMethod} variableCode={variableCode} />

        <Spacer size={theme.space.s6} />

        <BankAccountLabel>{t("bank_account_change:bank_account_number_is")}</BankAccountLabel>
        <Spacer size={theme.space.s4} />
        <BankAccountField>
          <BankAccount>{bankAccountNumber}</BankAccount>

          <CopyButton
            size="lg"
            value={bankAccountNumber}
            tooltip={<Tooltip>{t("bank_account_change:copy_number")}</Tooltip>}
          />
        </BankAccountField>

        <Spacer size={theme.space.s6} />

        <ButtonGroup>
          <RemindMeLaterButton autoFocus variant="secondary" onClick={onDismiss}>
            {t("bank_account_change:remind_me_later")}
          </RemindMeLaterButton>

          <ConfirmButton isSubmitting={isConfirming} onClick={onConfirm}>
            {t("bank_account_change:yes_changed")}
          </ConfirmButton>
        </ButtonGroup>
      </ModalContent>
    </Modal>
  )
}
