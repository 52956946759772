import { keyframes, styled, theme } from "../../styles/stitches.config"
import * as HoverCard from "@radix-ui/react-hover-card"

export const STooltipRoot = styled(HoverCard.Root)

export const STooltipTrigger = styled(HoverCard.Trigger)

export const STooltipArrow = styled(HoverCard.Arrow, {
  fill: theme.colors.white,
  width: theme.space.s5,
  height: theme.space.s4,
})

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
})

const slideUpAndFade = keyframes({
  "0%": { opacity: 1, transform: "translateY(0)" },
  "100%": { opacity: 0, transform: "translateY(-2px)" },
})

export const STooltipContent = styled(HoverCard.Content, {
  backgroundColor: theme.colors.white,
  filter: `drop-shadow(${theme.shadows.default})`,
  padding: theme.space.s6,
  maxWidth: `80vw`,

  noReducedMotion: {
    animationDuration: "300ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",

    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
    },
    '&[data-state="closed"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
    },
  },
})
