import { isArray } from "lodash"
import { AcquisitionFormValues } from "../pages/microsite/web/acquisitionForm/form/useAcquisitionFormSchema"
import { ContractTypeFormValues } from "../pages/microsite/web/acquisitionFormNew/contractType/contractTypeForm.utils"
import { HouseholdContactFormVales } from "../pages/microsite/web/acquisitionFormNew/contact/householdContactFormController"
import { BusinessContactFormVales } from "../pages/microsite/web/acquisitionFormNew/contact/businessContactForm.utils"
import { ConsumptionPointFormValues } from "../pages/microsite/web/acquisitionFormNew/consumptionPoint/consumptionPointForm.utils"
import { ProductionPointFormValues } from "../pages/microsite/web/acquisitionFormNew/productionPoint/productionPointForm.utils"
import { PaymentFormValues } from "../pages/microsite/web/acquisitionFormNew/payment/paymentForm.utils"

type NewAcquisitionFormValues = {
  contractTypeFormValues: ContractTypeFormValues
  householdContactFormValues?: HouseholdContactFormVales
  businessContactFormValues?: BusinessContactFormVales
  consumptionPointFormValues?: ConsumptionPointFormValues
  productionPointFormValues?: ProductionPointFormValues
  paymentFormValues: PaymentFormValues
}

type AnalyticsEvents = {
  formSubmission: {
    formData: (AcquisitionFormValues & { form: "old" }) | (NewAcquisitionFormValues & { form: "new" })
    formVariant: "C" | "D"
  }
  userData: { isClient: boolean }
  appInstallation: Record<string, never>
}

function getDataLayer(): object[] {
  return "dataLayer" in window && isArray(window.dataLayer) ? window.dataLayer : []
}

export function pushAnalyticsEvent<E extends keyof AnalyticsEvents>(event: E, data: AnalyticsEvents[E]) {
  getDataLayer().push({
    event,
    ...data,
  })
}
