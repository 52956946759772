import { ComponentProps } from "react"
import Separator from "../../components/Separator"
import Text from "../../components/Text"
import { Div } from "../../components/div/Div"
import { Flex } from "../../components/flex/Flex"
import InfoBox from "../../components/info/InfoBox"
import { Spacer } from "../../components/spacer/Spacer"
import { VictronActive, SolaxActive, Infigy, Goodwe } from "../../images/xd/icons"
import { theme } from "../../styles/stitches.config"
import {
  AdvertisementCard,
  AdvertisementHeader,
  AutomaticsDescription,
  AutomaticsKeyPointDescription,
  AutomaticsKeyPointHeader,
  AutomaticsKeyPoints,
  Logo,
  PartnerLogos,
} from "./Advertisement.styled"
import { Trans, useTranslation } from "react-i18next"
import { WEB_LINKS } from "../../constants/links"

function GoodweLogo(props: ComponentProps<typeof Logo>) {
  return (
    <Logo {...props}>
      <Goodwe />
      <Text type="textsLarge" color="primary" css={{ fontSize: 20, top: -5 }}>
        *
      </Text>
    </Logo>
  )
}

function SolaxLogoDisclaimer(props: ComponentProps<typeof Logo>) {
  return (
    <Logo {...props}>
      <SolaxActive />
      <Text type="textsLarge" color="primary" css={{ marginLeft: theme.space.s2.value, fontSize: 20, top: -5 }}>
        *
      </Text>
    </Logo>
  )
}

function InfigyLogo() {
  return (
    <Logo>
      <Infigy style={{ flexShrink: 0, maxWidth: 90 }} />
    </Logo>
  )
}

function DeltaLinkDisclaimer() {
  const { t } = useTranslation(["flexibility"])
  return (
    <Text type="textsSmall" color="primary">
      <Trans t={t} i18nKey="flexibility:delta_link_required_disclaimer">
        <a href={WEB_LINKS.eshopDeltaLink} target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }} />
      </Trans>
    </Text>
  )
}

export function Advertisement() {
  const { t } = useTranslation(["flexibility"])
  return (
    <>
      <AdvertisementCard>
        <Spacer size={theme.space.s2} />
        <AdvertisementHeader>{t("flexibility:data_advertisement_header")}</AdvertisementHeader>
        <Spacer size={theme.space.s2} />
        <Text type="textsLarge" color="texts" css={{ fontSize: 12, "@sm": { fontSize: 16 } }}>
          <Trans t={t} i18nKey="flexibility:data_advertisement_label">
            <strong />
          </Trans>
          <InfoBox
            inline
            // hack to get around the relative positioned "Coming soon tag" being rendered above the tooltip
            contentCSS={{ zIndex: 10 }}
            css={{ display: "none", "@sm": { display: "inline-flex" }, position: "relative", top: -5 }}
          >
            <Div css={{ maxWidth: 464 }}>
              <Text type="textsSmall">
                <Trans t={t} i18nKey="flexibility:data_advertisement_tooltip">
                  <strong />
                </Trans>
              </Text>
            </Div>
          </InfoBox>
        </Text>

        <Spacer size={theme.space.s7_5} />
        <Text type="headerH3">{t("flexibility:data_advertisement_brands_header")}</Text>
        <Spacer size={theme.space.s4} />

        <PartnerLogos>
          <Logo>
            <VictronActive style={{ minWidth: 64 }} />
          </Logo>
          <Separator orientation="vertical" color="dark" />
          <Logo>
            <SolaxActive />
          </Logo>

          <Separator orientation="vertical" color="dark" />
          <GoodweLogo />

          <Separator orientation="vertical" color="dark" />
          <InfigyLogo />
        </PartnerLogos>

        <Spacer size={theme.space.s4} />

        <Flex justify="end">
          <DeltaLinkDisclaimer />
        </Flex>
      </AdvertisementCard>

      <Spacer size={theme.space.s9} />

      <AdvertisementCard>
        <AdvertisementHeader>{t("flexibility:automatics_advertisement_header")}</AdvertisementHeader>
        <Spacer size={theme.space.s4} />
        <Flex
          align="center"
          css={{
            flexDirection: "column",
            gap: theme.space.s6,
            "@md": { flexDirection: "row", paddingRight: theme.space.s2 },
          }}
        >
          <AutomaticsDescription>{t("flexibility:automatics_advertisement_label")}</AutomaticsDescription>

          <AutomaticsKeyPoints>
            <Div css={{ maxWidth: 210 }}>
              <AutomaticsKeyPointHeader>{t("flexibility:automatics_save_up")}</AutomaticsKeyPointHeader>
              <Spacer size={theme.space.s2} />
              <AutomaticsKeyPointDescription>{t("flexibility:automatics_save_up_label")}</AutomaticsKeyPointDescription>
            </Div>

            <Flex direction="column">
              <Separator orientation="vertical" color="secondary" size="large" />
            </Flex>

            <Div>
              <AutomaticsKeyPointHeader>{t("flexibility:automatics_proteus_free")}</AutomaticsKeyPointHeader>
              <Spacer size={theme.space.s2} />
              <AutomaticsKeyPointDescription>
                {t("flexibility:automatics_proteus_free_label")}
              </AutomaticsKeyPointDescription>
            </Div>
          </AutomaticsKeyPoints>
        </Flex>

        <Spacer size={theme.space.s7_5} />

        <Text type="headerH3">{t("flexibility:automatics_brands_header")}</Text>

        <Spacer size={theme.space.s6} />

        <PartnerLogos>
          <Logo size="large">
            <VictronActive />
          </Logo>

          <Separator orientation="vertical" color="dark" />
          <SolaxLogoDisclaimer size="large" />
          <Separator orientation="vertical" color="dark" />
          <GoodweLogo size="large" />
        </PartnerLogos>

        <Spacer size={theme.space.s2} css={{ display: "block", "@md": { display: "none" } }} />
        <Flex justify="end">
          <DeltaLinkDisclaimer />
        </Flex>
      </AdvertisementCard>
    </>
  )
}
