import { ComponentProps, PropsWithChildren, ReactNode } from "react"
import { styled, theme } from "../../styles/stitches.config"
import { useCopyToClipboard } from "../../utils/clipboard"
import { IconCopy } from "@tabler/icons-react"
import { isNotNil } from "@nano-portal/shared"
import { STooltipArrow, STooltipContent, STooltipRoot, STooltipTrigger } from "./CopyButton.styled"

const SButton = styled("button", {
  all: "unset",
  cursor: "pointer",
  variants: {
    size: {
      lg: {
        width: theme.sizes.s7_5,
        height: theme.sizes.s7_5,
      },
      md: {
        width: theme.sizes.s6,
        height: theme.sizes.s6,
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
})

const SIconCopy = styled(IconCopy, {
  color: theme.colors.textsAlt,
})

type Props = ComponentProps<typeof SButton> & {
  value: string
  tooltip?: ReactNode
}

export function CopyButton(props: PropsWithChildren<Props>) {
  const { children, value, tooltip, ...rest } = props
  const copyToClipboard = useCopyToClipboard()

  function renderContent() {
    return (
      <SButton type="button" onClick={() => void copyToClipboard(value)} {...rest}>
        {children}
        <SIconCopy size={30} />
      </SButton>
    )
  }

  if (isNotNil(tooltip)) {
    return (
      <STooltipRoot openDelay={150}>
        <STooltipTrigger asChild>{renderContent()}</STooltipTrigger>
        <STooltipContent avoidCollisions side="top">
          <STooltipArrow offset={8} />
          {tooltip}
        </STooltipContent>
      </STooltipRoot>
    )
  }

  return (
    <SButton type="button" onClick={() => void copyToClipboard(value)} {...rest}>
      {children}
      <SIconCopy size={30} />
    </SButton>
  )
}
