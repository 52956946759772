import { useSessionStorage as externalUseSessionStorage } from "react-use"

export type SessionStorageValues = {
  key: `contractualAccountBankAccountChangeDismissed`
  payload: number[]
}

export function useSessionStorage<TKey extends SessionStorageValues["key"]>(
  key: TKey,
  initialValue?: Extract<SessionStorageValues, { key: TKey }>["payload"]
) {
  return externalUseSessionStorage(key, initialValue)
}
