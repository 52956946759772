import { Button } from "../../components/button/Button"
import { Div } from "../../components/div/Div"
import { SModalTitle, SModalDescription } from "../../components/modal/Modal.styled"
import { styled, theme, fontVariants } from "../../styles/stitches.config"

export const ModalContent = styled(Div, {
  maxWidth: 709,
})

export const ModalTitle = styled(SModalTitle, {
  marginTop: theme.space.s2,
  marginBottom: theme.space.s2,
  fontWeight: theme.fontWeights.bold,
})

export const ModalDescription = styled(SModalDescription, {
  ...fontVariants.textsSmall,
  color: theme.colors.primary,
  "@sm": { ...fontVariants.textsLarge, color: theme.colors.primary },
})

export const DescriptionParagraph = styled("p", {
  ...fontVariants.textsSmall,
  color: theme.colors.primary,
  "@sm": { ...fontVariants.textsLarge, color: theme.colors.primary },
})

export const BankAccountLabel = styled("p", {
  ...fontVariants.textsSmall,
  fontWeight: theme.fontWeights.bold,
  fontSize: 16,
  color: theme.colors.textsAlt,
  "@sm": { fontSize: 20 },
})

export const BankAccount = styled("p", {
  ...fontVariants.headerH1,
  fontSize: 22,
  color: theme.colors.secondary,
  fontWeight: theme.fontWeights.bold,
  "@sm": { fontSize: 50 },
})

export const BankAccountField = styled(Div, {
  display: "flex",
  gap: theme.space.s2,
  alignItems: "center",

  "@sm": {
    gap: theme.space.s4_5,
  },
})

export const Tooltip = styled("p", {
  ...fontVariants.textsLarge,
  color: theme.colors.primary,
})

export const ButtonGroup = styled(Div, {
  display: "flex",
  justifyContent: "end",
  flexWrap: "wrap",
  gap: theme.space.s6,
})

export const RemindMeLaterButton = styled(Button, {
  width: "100%",
  paddingInline: 0,
  paddingBlock: 0,
  "@sm": { width: 227 },
})

export const ConfirmButton = styled(Button, {
  width: "100%",
  paddingInline: 0,
  paddingBlock: 0,
  "@sm": { width: 183 },
})
