import { Outlet } from "react-router"
import { useSessionStorage } from "../../../utils/sessionStorage"
import { useSafeNumberParam } from "../../../hooks/useSafeNumberParam"
import { trpc } from "../../../api/trpc/trpc"
import { DeliveryPointType, PaymentMethod, isNil, isNotNil } from "@nano-portal/shared"
import { BankAccountChangeModal } from "../../../sections/bankAccountChange/BankAccountChangeModal"
import { toasts } from "../../../utils/toasts"
import { useTranslation } from "react-i18next"

function DeliveryPointLayoutWithBankAccountModal(props: {
  deliveryPointId: number
  contractualAccountId: number
  onModalDismiss: () => void
}) {
  const { t } = useTranslation(["bank_account_change"])
  const { data: contractualAccount, isLoading: contractualAccountLoading } = trpc.useQuery([
    "deliveryPoints.contractualAccounts.contractualAccount",
    props,
  ])
  const { data: bankAccountChangeConfirmation, isLoading: bankAccountChangeConfirmationLoading } = trpc.useQuery([
    "deliveryPoints.contractualAccounts.bankAccountChangeConfirmation",
    props,
  ])
  const { data: bankAccount, isLoading: bankAccountLoading } = trpc.useQuery(["bankAccounts.parentCompanyNew"])
  const confirmationMutation = trpc.useMutation(["deliveryPoints.contractualAccounts.confirmBankAccountChange"], {
    onSettled: () => {
      // UI stuff here to make sure the modal is gone since it is not closable by default
      toasts.success(t("bank_account_change:thank_you_toast"))
      props.onModalDismiss()
    },
  })

  const isLoading = contractualAccountLoading || bankAccountLoading || bankAccountChangeConfirmationLoading
  if (isLoading || isNil(contractualAccount) || isNil(bankAccount)) {
    return <Outlet />
  }

  const { paymentMethods, variableCode, parentCompanyBankAccountNumber } = contractualAccount
  const bankAccountNumberChangedOrChangeConfirmed =
    parentCompanyBankAccountNumber === bankAccount.bankAccountNumber || isNotNil(bankAccountChangeConfirmation)
  if (bankAccountNumberChangedOrChangeConfirmed) {
    return <Outlet />
  }

  function handleConfirm() {
    confirmationMutation.mutate(props)
  }

  const paymentMethod = paymentMethods.some((pm) => pm.paymentMethod === "Inkaso")
    ? PaymentMethod.BankEncashment
    : PaymentMethod.BankOrder

  return (
    <>
      <Outlet />
      <BankAccountChangeModal
        onDismiss={props.onModalDismiss}
        onConfirm={handleConfirm}
        isConfirming={confirmationMutation.isLoading}
        paymentMethod={paymentMethod}
        variableCode={variableCode}
        bankAccountNumber={bankAccount.bankAccountNumber}
      />
    </>
  )
}

export function DeliveryPointLayout() {
  const deliveryPointId = useSafeNumberParam("deliveryPointId")
  const [contractualAccountIdsModalDismissed, setContractualAccountIdsModalDismissed] = useSessionStorage(
    "contractualAccountBankAccountChangeDismissed",
    []
  )

  const { data: myself, isLoading: myselfLoading } = trpc.useQuery(["users.myself"])
  const { data: config, isLoading: configLoading } = trpc.useQuery(["service.config"])
  const { data: deliveryPoint, isLoading: deliveryPointLoading } = trpc.useQuery(
    ["deliveryPoints.detail", { deliveryPointId }],
    {
      enabled: config?.useNewBankAccount ?? false,
    }
  )

  // prozatimni hardcoded FF
  const modalEnabled =
    config?.useNewBankAccount || myself?.email === "david.brozik@dejw.net" || myself?.email === "jan.hicl@gmail.com"

  if (
    configLoading ||
    deliveryPointLoading ||
    isNil(deliveryPoint) ||
    myselfLoading ||
    isNil(myself) ||
    !modalEnabled
  ) {
    return <Outlet />
  }

  const consumptionData = deliveryPoint.typeData.get(DeliveryPointType.Consumption)
  if (isNil(consumptionData) || !deliveryPoint.isActive) {
    return <Outlet />
  }

  const modalOnDeliveryPointDismissed = contractualAccountIdsModalDismissed.includes(
    consumptionData.contractualAccountId
  )

  if (modalOnDeliveryPointDismissed) {
    return <Outlet />
  }

  return (
    <DeliveryPointLayoutWithBankAccountModal
      contractualAccountId={consumptionData.contractualAccountId}
      deliveryPointId={deliveryPointId}
      onModalDismiss={() =>
        setContractualAccountIdsModalDismissed([
          ...contractualAccountIdsModalDismissed,
          consumptionData.contractualAccountId,
        ])
      }
    />
  )
}
